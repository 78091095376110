import React from "react";
import "./Careers.css";
import JoinOurTeam from "./JoinOurTeam/JoinOurTeam";
import ApplicationForm from "../ApplicationForm/ApplicationForm";

const Careers = () => {
  return (
    <div>
      <div className="joiourteam">
        <JoinOurTeam />
      </div>
      <div className="careers-main-container">
        <p>
          Explore our current job openings and find the role that’s right for
          you. Whether you’re a seasoned professional or just starting your
          career, we have opportunities in software development, project
          management, AI and data science, cybersecurity, and more.
        </p>
        <h1 className="careers-heading">Careers</h1>
        <div>
          <p className="careers-content">
            At Agile TechAi, we believe that our people are our greatest asset.
            We are a dynamic and innovative company at the forefront of software
            development and artificial intelligence, and we are always looking
            for talented, driven individuals to join our team. If you're
            passionate about technology and want to be part of a company that
            values creativity, collaboration, and professional growth, Agile
            TechAi could be the perfect place for you.
          </p>
          <div className="apply-info">
            <h1>How to Apply</h1>
            <p>
              Applying is easy! Browse our open positions, upload your resume,
              and tell us why you’d be a great fit for Agile TechAi. We review
              all applications and will reach out if your skills and experience
              match our needs.
            </p>
            <p>
              At Agile TechAi, your career is more than just a job; it’s a
              journey of growth, innovation, and achievement. Join us, and let’s
              build the future of technology together.
            </p>
          </div>
        </div>
        <ApplicationForm />
      </div>
    </div>
  );
};

export default Careers;
