import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Services.css";
import { Link } from "react-router-dom";

const Services = () => {
  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          infinite: true,
          autoplay: true,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          infinite: true,
          autoplay: true,
          arrows: true,
        },
      },
    ],
    beforeChange: (current, next) => {
      if (window.innerWidth > 1024) {
        const slickTrack = document.querySelector(".slick-track");
        if (slickTrack)
          slickTrack.style.transform = "translate3d(0px, 0px, 0px)";
      }
    },
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div id="services" className="services-main-container">
      <h1 className="heading">Services</h1>
      <div className="slider">
        <Slider {...settings}>
          <Link className="service-link" to="/services/cloud">
            <div onClick={handleScrollToTop} className="service-item item-1">
              <div className="services-content">
                <h3 className="head">Cloud Services</h3>
                <p>Secure and scalable cloud infrastructure solutions.</p>
              </div>
            </div>
          </Link>
          <Link className="service-link" to="/services/Data-analytics">
            <div onClick={handleScrollToTop} className="service-item item-2">
              <div className="services-content">
                <h3>Data & Analytics</h3>
                <p>Transform data into actionable insights.</p>
              </div>
            </div>
          </Link>
          <Link className="service-link" to="/services/artificial-intelligence-automation">
            <div onClick={handleScrollToTop} className="service-item item-3">
              <div className="services-content">
                <h3>Artificial Intelligence & Automation</h3>
                <p>Enhance your business with AI-driven automation.</p>
              </div>
            </div>
          </Link>
        </Slider>
      </div>
    </div>
  );
};

export default Services;
