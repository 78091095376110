import React from "react";
import { Link } from "react-router-dom";
import { RiInstagramFill } from "react-icons/ri";
import { FaLinkedin, FaFacebookSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { IoLocationSharp } from "react-icons/io5";
import { IoIosMail } from "react-icons/io";
import { FaPhoneAlt, FaYoutube } from "react-icons/fa";

import CompanyImage from "../../Assets/AgileLogoLight.png";

import "./Footer.css";

const Footer = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This adds a smooth scrolling effect
    });
  };
  return (
    <div className="main-footer-container">
      <div className="footer-content-items">
        <div className="industry">
          <h3>CONTACT US</h3>
          <Link onClick={handleScrollToTop} to="/" className="link">
          <div className="footer-logo-content-container">
            <img
              src={CompanyImage}
              className="CompanyImage"
              alt="company-img"
            />
            <h4 style={{color: 'rgb(53,184,175)'}} className="footer-company-name">Agile TechAI</h4>
          </div>
          </Link>
          <div className="visit-address-container">
            <a
              href="https://www.google.com/maps/place/13085+Morris+Rd,+Alpharetta,+GA+30004,+USA/@34.0994462,-84.2611526,17z/data=!3m1!4b1!4m6!3m5!1s0x88f59fe1151fe897:0x7fc09824ae09334b!8m2!3d34.0994462!4d-84.2585777!16s%2Fg%2F11w1f113cn?entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D"
              className="visit-address"
            >
              <IoLocationSharp style={{ marginRight: "5px" }} />
              13085 MORRIS RD #10202 ALPHARETTA GA 30004-4112
            </a>
            <div className="visit-address-container">
              <a
                href="tel:+12163939454"
                className="visit-address"
                style={{ marginTop: "15px" }}
              >
                <FaPhoneAlt style={{ marginRight: "5px" }} />
                +1 (216) 393-9454
              </a>
            </div>
            <div className="visit-address-container">
              <a
                href="mailto:hr@agiletechai.com"
                className="visit-address"
                style={{ marginTop: "15px" }}
              >
                <IoIosMail style={{ marginRight: "5px" }} />
                hr@agiletechai.com
              </a>
            </div>
          </div>
          <div className="contact-icons-container">
            <a href="http://www.linkedin.com/in/agile-techai">
              <FaLinkedin />
            </a>
            <a href="http://www.youtube.com/@AgileTechAi">
              <FaYoutube />
            </a>
            <a href="https://www.facebook.com/profile.php?id=61565508022242">
              <FaFacebookSquare />
            </a>
            <a href="https://x.com/AgileTechai">
              <FaSquareXTwitter />
            </a>
            <a href="https://www.instagram.com/agiletechai/">
              <RiInstagramFill />
            </a>
          </div>
        </div>

        <div className="company">
          <h3>COMPANY</h3>
          <ul>
            <Link className="footer-item" to="/" onClick={handleScrollToTop}>
              <li>Home</li>
            </Link>
            <Link
              className="footer-item"
              to="/about"
              onClick={handleScrollToTop}
            >
              <li>About</li>
            </Link>
            <Link
              className="footer-item"
              to="/industry"
              onClick={handleScrollToTop}
            >
              <li>Industry</li>
            </Link>
            <Link
              className="footer-item"
              to="/services"
              onClick={handleScrollToTop}
            >
              <li>Services</li>
            </Link>
            <Link
              className="footer-item"
              to="/careers"
              onClick={handleScrollToTop}
            >
              <li>Careers</li>
            </Link>
            <Link
              className="footer-item"
              to="/contact"
              onClick={handleScrollToTop}
            >
              <li>Contact Us</li>
            </Link>
          </ul>
        </div>

        <div className="industry">
          <h3>INDUSTRY</h3>
          <ul>
            <Link
              className="footer-item"
              to="/industry/bank-financial"
              onClick={handleScrollToTop}
            >
              <li>Banking</li>
            </Link>
            <Link
              className="footer-item"
              to="/industry/healthcare"
              onClick={handleScrollToTop}
            >
              <li>Healthcare</li>
            </Link>
            <Link
              className="footer-item"
              to="/industry/e-commerce"
              onClick={handleScrollToTop}
            >
              <li>E-commerce</li>
            </Link>
            <Link
              className="footer-item"
              to="/industry/hotel-hospitality-solutions"
              onClick={handleScrollToTop}
            >
              <li>Hotel & Hospitality</li>
            </Link>
          </ul>
        </div>

        <div className="industry">
          <h3>SERVICES</h3>
          <ul>
            <Link
              className="footer-item"
              to="/services/cloud"
              onClick={handleScrollToTop}
            >
              <li>Cloud Services</li>
            </Link>
            <Link
              className="footer-item"
              to="/services/Data-analytics"
              onClick={handleScrollToTop}
            >
              <li>Data & Analytics</li>
            </Link>
            <Link
              className="footer-item"
              to="/services/artificial-intelligence-automation"
              onClick={handleScrollToTop}
            >
              <li>Artificial Intelligence & Automation</li>
            </Link>
          </ul>
        </div>
      </div>
      <div class="footer-terms-content">
        <ul class="footer-terms-links">
          <Link onClick={handleScrollToTop} style={{fontSize:"12px"}} className="link" to="/terms-and-conditions">
            <li>Terms & Conditions</li>
          </Link>
          <Link onClick={handleScrollToTop}  style={{fontSize:"12px"}} className="link" to="/privacy-policy">
            <li>Privacy Policy</li>
          </Link>
        </ul>
        <p className="copy">&copy; 2024 Agile TechAI. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
