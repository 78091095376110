import React from "react";
import EcomImg from "../../../Assets/ecom.jpg";

import "./Ecommerce.css";

const Ecommerce = () => {
  return (
    <div className="ecom-main-container">
      <h3 className="sm-heading">E-commerce:</h3>
      <div className="ecom-content">
        <img className="ecom-img" src={EcomImg} alt="" />
        <div>
          <h3 className="lg-heading">E-commerce:</h3>
          <p>
            At Agile TechAI, we specialize in creating powerful, scalable, and
            secure e-commerce solutions that drive business growth and enhance
            customer experiences. Our expertise in software development and
            artificial intelligence allows us to craft tailored solutions that
            meet the unique needs of online retailers, marketplaces, and digital
            commerce platforms.
          </p>
        </div>
      </div>
      <div>
        <h4>Overview of Services:</h4>
        <ul className="ecom-list-items">
          <li>
            <span>Custom E-commerce Platform Development:</span> We build
            bespoke e-commerce platforms that are feature-rich, user-friendly,
            and designed to scale with your business. Whether you're a startup
            or an established retailer, we provide solutions that are tailored
            to your specific requirements.
          </li>
          <li>
            <span>AI-Driven Personalization:</span> Enhance your customer’s
            shopping experience with AI powered personalization engines. Our
            solutions deliver personalized product recommendations, dynamic
            pricing, and targeted marketing, increasing conversion rates and
            customer loyalty.
          </li>
          <li>
            <span>Mobile Commerce Solutions: </span> Develop responsive and
            high-performance mobile apps that offer a seamless shopping
            experience across devices. Our mobile commerce solutions are
            optimized for speed, usability, and integration with existing
            systems.
          </li>
          <li>
            <span>Integration with Payment Gateways and Logistics:</span> Ensure
            smooth transactions and efficient order fulfillment by integrating
            your e-commerce platform with popular payment gateways, shipping
            carriers, and inventory management systems.
          </li>
          <li>
            <span>E-commerce Analytics and Business Intelligence:</span>{" "}
            Leverage advanced analytics to gain insights into customer behavior,
            sales trends, and inventory management. Our BI tools help you make
            data-driven decisions to optimize operations and boost
            profitability.
          </li>
          <li>
            <span>Omni-Channel Retail Solutions: </span>Provide a consistent and
            cohesive shopping experience across all channels, whether online,
            in-store, or on mobile. Our omni-channel solutions help you connect
            with customers wherever they are, ensuring a unified brand
            experience.
          </li>
          <li>
            <span>Security and Compliance:</span>Protect your e-commerce
            platform and customer data with top-tier security solutions. We
            implement secure payment processing, data encryption, and compliance
            with global e-commerce regulations like PCI DSS.
          </li>
          <li>
            <span>Content Management Systems (CMS) for E-commerce:</span>Easily
            manage your online store’s content, products, and promotions with
            user-friendly CMS solutions. We offer customized CMS integrations
            that allow you to control your e-commerce site with ease.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Ecommerce;
