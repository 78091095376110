import React from 'react';
import './Policies.css'; 

const PrivacyPolicy = () => {
    return (
        <div className="policy-container">
            <div className="section">
        <span className="sub-heading">Privacy Policy for Agile TechAi</span>
        <p>At Agile TechAi, your privacy is of paramount importance to us. This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information. Please review this policy carefully.</p>
      </div>

      <div className="section">
        <span className="sub-heading">Purpose of Data Collection</span>
        <p>We will clearly define the purposes for which your personal information is collected before or at the time of collection.</p>
      </div>

      <div className="section">
        <span className="sub-heading">Use and Retention of Data</span>
        <p>We will collect and use personal data solely for the purposes stated and other legitimate purposes, unless we obtain your consent or are required by law to use it otherwise. We will retain personal data only as long as necessary to fulfill these purposes.</p>
      </div>

      <div className="section">
        <span className="sub-heading">Data Collection Methods</span>
        <p>We will collect personal data through lawful and fair means, and, where appropriate, with your knowledge or consent.</p>
      </div>

      <div className="section">
        <span className="sub-heading">Accuracy and Relevance</span>
        <p>Personal information should be relevant to the purposes for which it is used and, to the extent necessary, should be accurate, complete, and up-to-date.</p>
      </div>

      <div className="section">
        <span className="sub-heading">Data Security</span>
        <p>We implement appropriate security measures to protect personal information against loss, theft, and unauthorized access, disclosure, copying, use, or modification.</p>
      </div>

      <div className="section">
        <span className="sub-heading">Access to Policies</span>
        <p>We will provide individuals with access to our policies and procedures related to the management of personal data upon request.</p>
      </div>

      <div className="section">
        <span className="sub-heading">Commitment to Privacy</span>
        <p>We are dedicated to conducting our business in accordance with these principles to ensure that personal data is handled securely and with the utmost respect for privacy.</p>
      </div>
        </div>
    );
};

export default PrivacyPolicy;
