import React from "react";
import OurMission from "../../Assets/ourmission.jpg";
import vision from "../../Assets/Vision.jpg";
import InnovationImage from "../../Assets/innovation.jpg";
import IntegrityImage from "../../Assets/Integrity.jpg";

import "./About.css";
const About = () => {
  return (
    <div className="about-main-container">
      <div>
        <h1 className="heading">About</h1>
        <div>
          <div className="two-about-items">
            <div className="about-item">
              <div className="about-content">
                <h2>Mission Statement</h2>
                <p>
                  At Agile TechAI, our mission is to revolutionize industries by
                  delivering cutting-edge AI-driven products and offering a full
                  spectrum of product engineering and software maintenance
                  services. We specialize in empowering sectors such as banking
                  and finance, healthcare, e-commerce, and hotel and hospitality
                  with innovative, efficient, and scalable technology solutions.
                  Our approach is rooted in deep expertise and a commitment to
                  excellence, ensuring that our solutions not only meet but
                  exceed the unique challenges of these industries. By
                  leveraging advanced AI technologies and industry best
                  practices, we drive growth, streamline operations, and pave
                  the way for sustained success and innovation in an
                  increasingly competitive market.
                </p>
              </div>
              <img src={OurMission} className="about-img" alt="our-mission" />
            </div>
            <div className="vision-card">
              <img src={vision} className="vision-img" alt="vision" />
              <div className="about-content">
                <h2>Vision</h2>
                <p>
                  Our vision is to lead the way in AI-driven technology
                  solutions, reshaping industries through unparalleled
                  innovation and excellence. We are committed to setting new
                  benchmarks in product engineering and software maintenance,
                  ensuring that our clients not only meet but exceed their
                  business objectives. By consistently delivering cutting-edge
                  solutions, we empower our clients to achieve exceptional
                  success and sustained growth, making us the trusted partner
                  for transformative technology in a competitive landscape.
                </p>
              </div>
            </div>
          </div>
          <div className="value-item">
            <div className="about-content">
              <h2>Values</h2>
              <div className="value-1">
                <div>
                  <p>
                    <span className="sub-heading">Innovation:</span>
                    Foster a culture of creativity and continuous improvement to
                    develop cutting-edge AI solutions that address the unique
                    challenges of our clients.
                  </p>
                  <p>
                    <span className="sub-heading">Customer-Centricity:</span>
                    Place our clients at the heart of everything we do,
                    delivering tailored solutions that meet their specific needs
                    and exceed their expectations.
                  </p>
                </div>
                <img
                  src={InnovationImage}
                  className="innovation-img"
                  alt="innovation-image"
                />
              </div>
              <div className="value-2">
                <img
                  src={IntegrityImage}
                  className="integrity-img"
                  alt="integrity-img"
                />
                <div>
                  <p>
                    <span className="sub-heading">Excellence:</span>
                    Strive for the highest standards in product development,
                    engineering, and maintenance, ensuring the delivery of
                    reliable and superior technology solutions.
                  </p>
                  <p>
                    <span className="sub-heading">Integrity:</span>
                    Uphold transparency, honesty, and ethical practices in all
                    our dealings, building trust with our clients, partners, and
                    employees. <br />
                    Collaboration: Promote teamwork and partnerships both within
                    our company and with our clients, fostering an environment
                    where innovative ideas and solutions can thrive.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
