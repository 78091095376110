import React, { useState } from "react";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { FaExternalLinkAlt } from "react-icons/fa";
import "react-phone-input-2/lib/style.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Contact.css";

const Contact = () => {
  const [phone, setPhone] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitted Phone Number:", phone);
    try {
      console.log("Submitted Phone Number:", phone);
      toast.success("Message sent successfully!");
    } catch (error) {
      toast.error("Failed to send the message.");
    }

    setFormData({
      name: "",
      email: "",
      message: "",
    });
    setPhone("");
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <ToastContainer />

      <div className="contact-bg-container">
        <h1 className="contact-heading">Contact Us</h1>
        <Link
          onClick={handleScrollToTop}
          className="link-book blink"
          to="/book-appointment"
        >
          Book an Appointment <FaExternalLinkAlt />
        </Link>
      </div>
      <div className="contact-form-container">
        <p className="contact-content">
          At Agile TechAi, we are always here to connect with you. Whether you
          have a question, need support, or want to discuss how we can work
          together, we’re ready to assist. Reach out to us using the details
          below or fill out our contact form, and we’ll get back to you
          promptly.
        </p>
        <div className="contact-details-container">
          <div className="form-wrapper">
            <h4 className="contact-sub-heading">Contact Us:</h4>

            <form className="form-content" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label htmlFor="phone">Phone</label>
                <PhoneInput
                  country={"us"}
                  value={phone}
                  onChange={setPhone}
                  inputProps={{
                    name: "phone",
                    required: true,
                  }}
                />
              </div>

              <div>
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </div>

              <button className="contact-button" type="submit">
                Submit
              </button>
            </form>
          </div>
          <div className="map-container">
            <div>
              <h4 className="visit-heading">Visit us:</h4>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3303.8319148707633!2d-84.26115262428152!3d34.099446173139064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f59fe1151fe897%3A0x7fc09824ae09334b!2s13085%20Morris%20Rd%2C%20Alpharetta%2C%20GA%2030004%2C%20USA!5e0!3m2!1sen!2sin!4v1725125814305!5m2!1sen!2sin"
                width="600"
                height="450"
                style={{ border: 0, borderRadius: "10px" }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Map"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
