import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import "../App.css"
const Layout = () => {
  return (
    <div className="body-main-container">  
      <Header />
      <div className="outlets-container">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
