import React from 'react'
import "./JoinOurTeam.css"

const JoinOurTeam = () => {
  return (
    <div className='joinourteam-main-container'>
            <h1 className='join-heading'>Join Our Team</h1>
    </div>
  )
}

export default JoinOurTeam