
import "./App.css";
import Body from "./components/Body";
function App() {
  return (
    <div>
      <Body/>
    </div>
  );
}

export default App;
