import React from "react";
import HealthImg from "../../../Assets/health.jpg";

import "./Healthcare.css";

const Healthcare = () => {
  return (
    <div className="health-main-container">
      <h3 className="sm-heading">Healthcare:</h3>

      <div className="health-content">
        <img className="health-img" src={HealthImg} alt="" />
        <div>
          <h3 className="lg-heading">Healthcare:</h3>
          <p>
            At Agile TechAI, we are dedicated to transforming the healthcare
            industry through innovative software solutions and advanced
            artificial intelligence. Our mission is to empower healthcare
            providers, payers, and patients with technology that enhances care
            delivery, improves outcomes, and optimizes operations.
          </p>
        </div>
      </div>
      <div>
        <h4>Overview of Services:</h4>
        <ul className="health-list-items">
          <li>
            <span>Custom Healthcare Software Development:</span> We design and
            develop tailor-made solutions that address the specific needs of
            healthcare organizations, including electronic health records (EHR)
            systems, patient management software, and telemedicine platforms.
          </li>
          <li>
            <span>Healthcare Data Integration and Interoperability:</span>{" "}
            Facilitate seamless data exchange across systems and platforms to
            ensure that healthcare providers have access to comprehensive
            patient information when and where they need it.
          </li>
          <li>
            <span>Patient Engagement and Telehealth:</span> Enhance patient
            engagement through user friendly telehealth solutions, mobile health
            apps, and patient portals that offer remote care and continuous
            communication between providers and patients.
          </li>
          <li>
            <span>Compliance and Security: </span>Ensure that all solutions
            comply with healthcare regulations like HIPAA, and implement robust
            security measures to protect sensitive patient data from breaches
            and unauthorized access.
          </li>
          <li>
            <span>Healthcare Analytics:</span> Provide advanced analytics
            solutions that help healthcare organizations gain insights into
            patient care, operational efficiency, and financial performance.
          </li>
        </ul>
      </div>
      <p>
        By partnering with Agile TechAI, healthcare organizations gain access to
        innovative technology that not only meets the demands of today’s
        healthcare landscape but also positions them for future success. Our
        solutions are designed to enhance the quality of care, streamline
        operations, and improve the patient experience, all while ensuring the
        highest levels of data security and compliance.{" "}
      </p>
    </div>
  );
};

export default Healthcare;
