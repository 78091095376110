import React from "react";
import InnovativeImg from "../../Assets/Innovative.png";
import CollaborativeImg from "../../Assets/Collaborative.png";
import ProfessionalImg from "../../Assets/Professional.png";
import InclusiveImg from "../../Assets/Inclusive.png";
import CompetitiveBenefitsImg from "../../Assets/benefits.png";
import WorkLifeImg from "../../Assets/Work-Life.png";

import "./WhyJoinUs.css";

const WhyJoinUs = () => {
  return (
    <div className="why-join-us">
      <div className="why-bgimg">
        <h1>Why Join Us?</h1>
      </div>
      <div className="why-content-container">
        <div className="why-content">
          <div className="section-orange">
            <p>
              <span className="sub-heading">Innovative Projects:</span>
              Work on cutting-edge projects that push the boundaries of
              technology across industries such as Banking and Finance,
              Healthcare, E-commerce, and Hotel & Hospitality.
            </p>
            <img
              src={InnovativeImg}
              className="section-img"
              alt="InnovativeImg"
            />
          </div>
          <div className="section-blue">
            <img
              src={CollaborativeImg}
              className="section-img"
              alt="CollaborativeImg"
            />
            <p>
              <span className="sub-heading">Collaborative Environment:</span>
              Be part of a team-oriented culture that fosters collaboration,
              knowledge sharing, and collective problem-solving. We value
              diverse perspectives and believe that the best ideas come from
              working together.
            </p>
          </div>
          <div className="section-orange">
            <p>
              <span className="sub-heading">Professional Growth:</span>
              We are committed to your development and offer numerous
              opportunities for learning and advancement. Whether through
              training programs, mentorship, or hands-on experience with the
              latest technologies, we support your career growth at every stage.
            </p>
            <img
              src={ProfessionalImg}
              className="section-img"
              alt="ProfessionalImg"
            />
          </div>
          <div className="section-blue">
            <img
              src={InclusiveImg}
              className="section-img"
              alt="InclusiveImg"
            />
            <p>
              <span className="sub-heading">Inclusive Culture:</span>
              At Company, we embrace diversity and strive to create an inclusive
              environment where everyone feels valued and respected. We believe
              that a diverse workforce leads to richer ideas and stronger teams.
            </p>
          </div>
          <div className="section-orange">
            <p>
              <span className="sub-heading">Work-Life Balance:</span>
              We understand the importance of balance and offer flexible work
              arrangements to help you manage your professional and personal
              life.
            </p>
            <img src={WorkLifeImg} className="section-img" alt="Work-LifeImg" />
          </div>
          <div className="section-blue">
            <img
              src={CompetitiveBenefitsImg}
              className="section-img"
              alt="CompetitiveImg"
            />
            <p>
              <span className="sub-heading">Competitive Benefits:</span>
              Enjoy a comprehensive benefits package that includes health
              insurance, retirement plans, paid time off, and other perks that
              support your well-being and financial security.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyJoinUs;
