import React, { useState, useEffect } from "react";
import "./Home.css";
import About from "../About/About";
import Industry from "../Industry/Industry";
import Services from "../Services/Services";
import WhyJoinUs from "../WhyJoinUs/WhyJoinUs";
import Contact from "../Contact/Contact";

const Home = () => {
  const [visibleParaIndex, setVisibleParaIndex] = useState(0);
  const [isTabletOrSmaller, setIsTabletOrSmaller] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsTabletOrSmaller(window.innerWidth <= 800);
    };

    window.addEventListener("resize", handleResize);

    if (isTabletOrSmaller) {
      const interval = setInterval(() => {
        setVisibleParaIndex((prevIndex) => (prevIndex + 1) % 3);
      }, 4000);

      return () => clearInterval(interval);
    }
    
    return () => window.removeEventListener("resize", handleResize);
  }, [isTabletOrSmaller]);

  return (
    <div>
      <div className="main-home-container">
        <div className="home-content">
          <h1 className="heading">
            Welcome to <span className="company-name">Agile TechAI</span>
          </h1>
          <div className="para-container">
            <p className={`para ${visibleParaIndex === 0 || !isTabletOrSmaller ? "show" : "hide"}`}>
              At Agile TechAI, we are committed to developing and delivering
              customized technology solutions that enable businesses to succeed
              in the digital age. Our experience includes a wide range of
              services, from IT Strategy Consulting to Customized Software
              Development, all tailored to your organization's specific
              requirements.
            </p>
            <p className={`para ${visibleParaIndex === 1 || !isTabletOrSmaller ? "show" : "hide"}`}>
              We specialize in developing unique AI-driven products and provide
              complete Product Engineering and Software Maintenance services.
              Our cutting-edge solutions are meticulously designed for
              industries such as banking and finance, healthcare, e commerce,
              and hotel and hospitality, ensuring that our clients benefit from
              creative, efficient, and scalable technology that promotes growth
              and success.
            </p>
            <p className={`para ${visibleParaIndex === 2 || !isTabletOrSmaller ? "show" : "hide"}`}>
              Partner with us to transform your business with our cutting-edge
              technological solutions and experience the future of innovation
              now.
            </p>
          </div>
        </div>
      </div>
      <About />
      <Industry />
      <Services />
      <WhyJoinUs />
      <Contact />
    </div>
  );
};

export default Home;
