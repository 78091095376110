import React, { useState, useEffect, useRef } from "react";
import AgileTechAi_Icon from "../../Assets/AgileLogoLight.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { CgClose } from "react-icons/cg";
import { Link } from "react-router-dom";
import { FaAngleRight, FaAngleDown } from "react-icons/fa6";

import "./Header.css";

const Header = () => {
  const [menu, setMenu] = useState(true);
  const [industryDropdown, setIndustryDropdown] = useState(false);
  const [servicesDropdown, setServicesDropdown] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        // Clicked outside of menu area
        setMenu(true); // Close the menu
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setMenu(true);
  };

  const toggleIndustryDropdown = () => {
    setIndustryDropdown(!industryDropdown);
    setServicesDropdown(false); // Close other dropdown if open
  };

  const toggleServicesDropdown = () => {
    setServicesDropdown(!servicesDropdown);
    setIndustryDropdown(false); // Close other dropdown if open
  };

  return (
    <div className="main-nav-container" ref={menuRef}>
      <div className="image-menu">
        <Link onClick={handleScrollToTop} to="/" className="link">
          <div className="logo-content-container">
            <img
              className="header-icon"
              src={AgileTechAi_Icon}
              alt="AgileTechAi_Icon"
            />
            <div>
              <h4 style={{ color: 'rgb(53,184,175)' }} className="header-company-name">Agile TechAI</h4>
              <p style={{ color: 'rgb(53,184,175)' }} className="company-slog">Empowering Careers</p>
            </div>
          </div>
        </Link>

        {menu ? (
          <GiHamburgerMenu
            onClick={() => setMenu(!menu)}
            className="menu-icon"
          />
        ) : (
          <CgClose className="menu-icon" onClick={() => setMenu(!menu)} />
        )}
      </div>
      <div className="nav">
        <nav>
          <ul
            className={`nav-items ${menu ? "open-nav-item" : "close-nav-item"}`}
          >
            <Link className="link" to="/">
              <li onClick={handleScrollToTop} className="item">
                Home
              </li>
            </Link>
            <Link className="link" to="/about">
              <li onClick={handleScrollToTop} className="item">
                About Us
              </li>
            </Link>
            <li onClick={toggleIndustryDropdown} className="dropdown item">
              Industry
              {industryDropdown && (
                <ul className="dropdown-menu">
                  <Link className="link" to="industry/bank-financial">
                    <li onClick={handleScrollToTop} className="drop-li">
                      Banking & Financial
                    </li>
                  </Link>
                  <Link className="link" to="industry/healthcare">
                    <li onClick={handleScrollToTop} className="drop-li">
                      Healthcare
                    </li>
                  </Link>
                  <Link className="link" to="industry/e-commerce">
                    <li onClick={handleScrollToTop} className="drop-li">
                      E-commerce
                    </li>
                  </Link>
                  <Link
                    className="link"
                    to="industry/hotel-hospitality-solutions"
                  >
                    <li onClick={handleScrollToTop} className="drop-li">
                      Hotel & Hospitality Solutions
                    </li>
                  </Link>
                </ul>
              )}
              {industryDropdown ? <FaAngleDown /> : <FaAngleRight />}
            </li>
            <li onClick={toggleServicesDropdown} className="dropdown item">
              Services
              {servicesDropdown && (
                <ul className="dropdown-menu">
                  <Link className="link" to="/services/cloud">
                    <li onClick={handleScrollToTop} className="drop-li">
                      Cloud Services
                    </li>
                  </Link>
                  <Link className="link" to="/services/Data-analytics">
                    <li onClick={handleScrollToTop} className="drop-li">
                      Data & Analytics
                    </li>
                  </Link>
                  <Link
                    className="link"
                    to="services/artificial-intelligence-automation"
                  >
                    <li onClick={handleScrollToTop} className="drop-li">
                      Artificial Intelligence & Automation
                    </li>
                  </Link>
                </ul>
              )}
              {servicesDropdown ? <FaAngleDown /> : <FaAngleRight />}
            </li>
            <Link className="link" onClick={handleScrollToTop} to="careers">
              <li className="item">Careers</li>
            </Link>
            <Link className="link" onClick={handleScrollToTop} to="contact">
              <li className="item">Contact Us</li>
            </Link>
          </ul>
        </nav>
      </div>
    </div>
  );
};  

export default Header;
