import React from "react";
import CloudImg from "../../../Assets/cloud.jpg";
import "./Cloud.css";
const Cloud = () => {
  return (
    <div className="cloud-main-container">
      <div className="">
        <h1 className="cloud-heading">Cloud Services:</h1>
        <div className="cloud-container">
          <p>
            Harness the full potential of the cloud with our comprehensive suite
            of cloud services, meticulously designed for businesses seeking to
            achieve unmatched scalability, flexibility, and cost efficiency. Our
            expertise spans the entire cloud lifecycle, from crafting a robust
            cloud strategy tailored to your specific business needs, to
            executing seamless migrations that minimize downtime and risk. We
            excel in deploying cloud environments that are both resilient and
            adaptable, ensuring they meet the highest standards of performance
            and security. Beyond deployment, our ongoing management services
            provide vigilant monitoring and proactive maintenance, ensuring that
            your cloud infrastructure operates at peak efficiency, fortified
            against potential threats. Trust us to be your strategic partner in
            navigating the complexities of the cloud, delivering solutions that
            drive innovation and empower your business to thrive in the digital
            age.
          </p>
          <img src={CloudImg} className="cloud-img" alt="cloud" />
        </div>
      </div>
    </div>
  );
};

export default Cloud;
