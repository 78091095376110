import React from "react";
import DataAnaImg from "../../../Assets/dataAna.jpg";
import "./DataAnalytics.css";
const DataAnalytics = () => {
  return (
    <div className="data-main-container">
      <div className="">
        <h1 className="data-heading">Data & Analytics:</h1>
        <div className="data-container">
          <p>
            Our data and analytics services empower organizations to extract
            critical insights from vast data sets, driving precision in decision
            making and strategic foresight. Leveraging cutting-edge technologies
            and advanced methodologies, we provide end-to end solutions
            encompassing data management, sophisticated analytics, and robust
            business intelligence frameworks. Our expertise ensures that your
            data is not just managed but transformed into a powerful asset that
            propels your business forward, enabling you to anticipate market
            trends, optimize operations, and enhance competitive advantage. We
            tailor our solutions to align with your specific needs, ensuring
            that your organization harnesses the full potential of its data
          </p>
          <img src={DataAnaImg} className="data-img" alt="data" />
        </div>
      </div>
    </div>
  );
};

export default DataAnalytics;
