// Agile Excel ac //  https://script.google.com/macros/s/AKfycbwKmG5zHf35ijv1q7v2KoFaGRYmZy2HMyV1VbPJLGHHU2JrKDsEUe6drNpUhZyHqsbomg/exec

import React, { useState, useEffect, useRef } from "react";
import DarkImage from "../../Assets/AgileLogoDark.png"; // Replace with your dark image
import LightImage from "../../Assets/AgileLogoLight.png"; // Replace with your light image
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AppointmentForm.css";

// CustomDropdown component for selecting time zone
const CustomDropdown = ({ options, selectedOption, onOptionSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Close dropdown if clicked outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleOptionClick = (option) => {
    onOptionSelect(option);
    setIsOpen(false);
  };

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <div
        className="custom-dropdown-selected"
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedOption || "Select Time Zone"}
      </div>
      {isOpen && (
        <ul className="custom-dropdown-options">
          {options.map((option, index) => (
            <li
              key={index}
              className="custom-dropdown-option"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

// AppointmentForm component for booking an appointment
const AppointmentForm = () => {
  const [formData, setFormData] = useState({
    timezone: "",
    name: "",
    selectedDate: "",
    selectedTime: "",
    email: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isDarkImage, setIsDarkImage] = useState(true);

  useEffect(() => {
    let interval;
    if (isLoading) {
      interval = setInterval(() => {
        setIsDarkImage((prevIsDarkImage) => !prevIsDarkImage);
      }, 500); // Change images every 0.5 seconds
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isLoading]);

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    // Agile Excel URL  // https://script.google.com/macros/s/AKfycbwKmG5zHf35ijv1q7v2KoFaGRYmZy2HMyV1VbPJLGHHU2JrKDsEUe6drNpUhZyHqsbomg/exec
    const scriptURL = "https://script.google.com/macros/s/AKfycbwKmG5zHf35ijv1q7v2KoFaGRYmZy2HMyV1VbPJLGHHU2JrKDsEUe6drNpUhZyHqsbomg/exec"; 
  
    const selectedDate = new Date(
      `${formData.selectedDate}T${formData.selectedTime}`
    );
    const now = new Date();
    if (selectedDate <= now) {
      toast.error("Please select a future date and time.");
      setIsLoading(false);
      return;
    }
  
    try {
      const formDataToSubmit = new FormData();
      Object.keys(formData).forEach((key) => {
        formDataToSubmit.append(key, formData[key]);
      });
  
      const response = await fetch(scriptURL, {
        method: "POST",
        body: formDataToSubmit,
      });
  
      if (response.ok) {
        setTimeout(() => {
          setFormData({
            timezone: "",
            name: "",
            selectedDate: "",
            selectedTime: "",
            email: "",
            message: "",
          });
          setIsLoading(false);
          toast.success("Appointment successfully booked!");
        }, 1500);
      } else {
        throw new Error("Failed to submit form");
      }
    } catch (error) {
      toast.error("Error submitting the form. Please try again.");
      console.error("Error!", error.message);
      setIsLoading(false);
    }
  };

  // Handle form field changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle time zone selection
  const handleTimezoneSelect = (timezone) => {
    setFormData({ ...formData, timezone });
  };

  // Scroll to top function
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-booking-container">
      <h1 className="book-heading">Book an Appointment</h1>
      {isLoading ? (
        <div className="loading-indicator">
          <img
            src={isDarkImage ? DarkImage : LightImage}
            alt="Loading..."
          />
          <strong style={{ color: "#fff" }}>Booking your appointment...</strong>
        </div>
      ) : (
        <div className="appointment-main-container">
          <div className="book-content">
            <h2>Connect with Us Today!</h2>
            <p>
              Whether you have questions, need expert advice, or want to
              schedule a detailed consultation, we're here to help. Booking an
              appointment with us is quick and easy. Just choose your preferred
              date, time, and time zone, and we'll make sure you're connected
              with the right expert at the right time.
            </p>
          </div>
          <div className="form-container-booking">
            <h4 style={{ color: "#fff" }}>Schedule Meeting:</h4>
            <form className="form-appointment" onSubmit={handleSubmit}>
              <div>
                <label>Time Zone</label>
                <CustomDropdown
                  options={["UTC", "GMT", "PST", "EST"]}
                  selectedOption={formData.timezone}
                  onOptionSelect={handleTimezoneSelect}
                />
              </div>

              <div>
                <label>Name</label>
                <input
                  className="custum-input"
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>

              <div>
                <label>Select Date</label>
                <input
                  className="custum-input"
                  type="date"
                  name="selectedDate"
                  value={formData.selectedDate}
                  onChange={handleChange}
                  min={new Date().toISOString().split("T")[0]} // Restrict to future dates
                  required
                />
              </div>

              <div>
                <label>Select Time</label>
                <input
                  className="custum-input"
                  type="time"
                  name="selectedTime"
                  value={formData.selectedTime}
                  onChange={handleChange}
                  required
                />
              </div>

              <div>
                <label>Email</label>
                <input
                  className="custum-input"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div>
                <label>Message</label>
                <textarea
                  className="custum-input"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-button-container">
                <button
                  onClick={handleScrollToTop}
                  className="appointmentsubmit-button"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      <ToastContainer
        className="toast-container"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        draggable
      />
    </div>
  );
};

export default AppointmentForm;
