import React from "react";
import HotelImg from "../../../Assets/hotel.jpg";
import "./Hotel.css";

const Hotel = () => {
  return (
    <div className="hotel-main-container"> 
      <h3 className="sm-heading">Hotel & Hospitality: </h3>
      <div className="hotel-content">
        <img className="hotel-img" src={HotelImg} alt="hotel-img" />
        <div>
          <h3 className="lg-heading">Hotel & Hospitality:</h3>
          <p>
            At Agile TechAi, we are dedicated to delivering innovative software
            solutions that cater to the unique needs of the hotel and
            hospitality industry. Our expertise lies in enhancing guest
            experiences, optimizing operations, and driving revenue growth
            through cutting-edge technology.
          </p>
        </div>
      </div>
      <div>
        <h4>Overview of Services:</h4>
        <ul className="hotel-list-items">
          <li>
            <span>Customer Relationship Management (CRM):</span> In the
            hospitality industry, understanding and anticipating guest needs is
            key to success. Our CRM solutions are designed to help you create
            personalized experiences that keep guests coming back. With our
            systems, you can:
            <ul>
              <li>
                Personalize Guest Interactions: Use detailed guest profiles to
                tailor communications, offers, and services to individual
                preferences.
              </li>
              <li>
                Automate Marketing Campaigns: Run targeted email and SMS
                campaigns to engage guests before, during, and after their stay.
              </li>
              <li>
                Streamline Operations: Integrate CRM with your property
                management system (PMS) to manage bookings, check-ins, and other
                guest interactions seamlessly.
              </li>
            </ul>
          </li>
          <li>
            <span>Data Analytics & Reporting:</span> Harness the power of data
            to transform your hospitality business. Our data analytics and
            reporting services provide:
            <ul>
              <li>
                Actionable Insights: Analyze guest behavior, market trends, and
                operational efficiency to make data-driven decisions.
              </li>
              <li>
                Real-Time Reporting: Access up-to-date reports on occupancy,
                revenue, and guest satisfaction to monitor performance and make
                timely adjustments.
              </li>
              <li>
                Predictive Analytics: Forecast demand, optimize pricing
                strategies, and improve resource allocation based on predictive
                models.
              </li>
            </ul>
          </li>
          <li>
            <span>Cybersecurity Solutions:</span> In an industry where guest
            trust is paramount, cybersecurity cannot be compromised. Our
            cybersecurity solutions offer:
            <ul>
              <li>
                Comprehensive Protection: Safeguard sensitive guest data and
                financial information with advanced encryption and security
                protocols.
              </li>
              <li>
                Threat Detection and Response: Implement proactive monitoring
                and rapid response strategies to protect against potential
                threats and breaches.
              </li>
              <li>
                Compliance Assurance: Ensure compliance with data protection
                regulations like GDPR and PCI DSS, avoiding penalties and
                reputational damage.
              </li>
            </ul>
          </li>
          <li>
            <span>IT Support & Managed Services:</span> Let us handle your
            technology needs so you can focus on hospitality. Our IT support and
            managed services include:
            <ul>
              <li>
                24/7 Technical Support: Access round-the-clock support to
                resolve IT issues quickly and efficiently.
              </li>
              <li>
                System Maintenance: Keep your IT infrastructure up-to-date and
                running smoothly with regular maintenance and updates.
              </li>
              <li>
                Managed IT Services: Outsource your IT operations to us,
                allowing your internal team to concentrate on strategic
                initiatives and guest services.
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <p>
        By partnering with Agile TechAi, hotel and hospitality businesses gain
        access to innovative technology that enhances guest satisfaction,
        streamlines operations, and drives growth. Our solutions are tailored to
        meet the specific needs of your industry, ensuring that your business
        stays ahead in a competitive market.
      </p>
    </div>
  );
};

export default Hotel;
