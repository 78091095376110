import React from "react";
import BankingImg from "../../../Assets/banking.jpg";
import "./Banking.css";

const Banking = () => {
  return (
    <div className="bank-main-container">
      <h3 className="sm-heading">Banking: </h3>

      <div className="banking-content">
        <img className="banking-img" src={BankingImg} alt="banking-img" />

        <div>
          <h3 className="lg-heading">Banking: </h3>
          <p>
            At Agile TechAI, we understand the complexities and regulatory
            demands of the banking and financial sector. Our expertise lies in
            delivering innovative, secure, and compliant software solutions that
            empower financial institutions to stay ahead in a rapidly evolving
            industry.
          </p>
        </div>
      </div>
      <div>
        <h4>Overview of Services:</h4>
        <ul className="bank-list-items">
          <li>
            <span>Custom Software Development:</span> Tailored solutions that
            meet the unique needs of banking and financial institutions, from
            core banking systems to mobile banking applications.
          </li>
          <li>
            <span>Digital Transformation:</span> Modernize legacy systems and
            adopt cloud-based solutions to enhance agility, efficiency, and
            customer engagement.
          </li>
          <li>
            <span>Risk Management and Compliance:</span> Implement robust
            solutions that ensure regulatory compliance, data security, and risk
            management across all operations.
          </li>
          <li>
            <span>Blockchain and FinTech Integration:</span> Harness the power
            of blockchain technology and FinTech innovations to offer
            cutting-edge financial services and products.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Banking;
