import React from "react";
import "./Policies.css";

const TermsConditions = () => {
  return (
    <div className="policy-container">
      <div className="section">
        <span className="sub-heading">Website Terms and Conditions</span>
        <p>
          <strong>Acceptance of Terms:</strong> By accessing and using this
          website, you acknowledge and agree to comply with these Terms and
          Conditions of Use, along with all applicable laws and regulations. If
          you do not agree to any of the terms stated herein, you are prohibited
          from using or accessing this site. All materials on this site are
          protected by copyright and trademark laws.
        </p>
      </div>

      <div className="section">
        <span className="sub-heading">Use License</span>
        <p>
          We grant you a limited, non-exclusive, and non-transferable license to
          temporarily download one copy of the materials (data or software) on
          Agile TechAi’s website solely for personal, non-commercial use. This
          license does not transfer any title or ownership of the materials to
          you. Under this license, you may not:
        </p>
        <ul className="policy-ul">
          <li>Modify or duplicate the materials.</li>
          <li>
            Utilize the materials for any commercial purpose or public display,
            whether business or non-business related.
          </li>
          <li>
            Attempt to decompile or reverse engineer any software or materials
            on our site.
          </li>
          <li>
            Remove any copyright or proprietary notations from the materials.
          </li>
          <li>
            Transfer the materials to another individual or "mirror" the
            materials on another server.
          </li>
        </ul>
        <p>
          This license will automatically terminate if you violate any of these
          restrictions and may be terminated by Agile TechAi at any time. Upon
          termination of this license or upon termination of your viewing
          privileges, you must destroy any downloaded materials in your
          possession, whether in electronic or physical form.
        </p>
      </div>

      <div className="section">
        <span className="sub-heading">Disclaimer</span>
        <p>
          The materials on Agile TechAi’s site are provided "as is," without
          warranty of any kind. Agile TechAi disclaims all warranties, express
          or implied, including but not limited to implied warranties of
          merchantability, fitness for a particular purpose, or non-infringement
          of intellectual property rights. Furthermore, Agile TechAi does not
          guarantee or make any representations regarding the accuracy, likely
          results, or reliability of the use of the materials on its website or
          otherwise relating to such materials or on any sites linked to this
          website.
        </p>
      </div>

      <div className="section">
        <span className="sub-heading">Limitations of Liability</span>
        <p>
          In no event shall Agile TechAi or its suppliers be liable for any
          damages (including, without limitation, damages for loss of data or
          profit, or due to business interruption) arising out of the use or
          inability to use the materials on Agile TechAi’s website, even if
          Agile TechAi or an authorized representative has been advised orally
          or in writing of the possibility of such damage. Some jurisdictions do
          not allow limitations on implied warranties or limitations of
          liability for consequential or incidental damages, so these
          limitations may not apply to you.
        </p>
      </div>

      <div className="section">
        <span className="sub-heading">Modifications and Errata</span>
        <p>
          The materials on Agile TechAi’s site may contain typographical,
          photographic, or technical errors. Agile TechAi does not warrant that
          any of the materials on its site are accurate, complete, or current.
          Agile TechAi may make changes to the materials contained on its site
          at any time without notice. However, Agile TechAi does not commit to
          updating the materials.
        </p>
      </div>

      <div className="section">
        <span className="sub-heading">Links</span>
        <p>
          Agile TechAi has not reviewed all of the sites linked to its website
          and is not responsible for the content of any such linked site. The
          inclusion of any link does not imply endorsement by Agile TechAi of
          the site. Use of any such linked site is at the user’s own risk.
        </p>
      </div>

      <div className="section">
        <span className="sub-heading">Modifications to Terms of Use</span>
        <p>
          Agile TechAi reserves the right to update these Terms and Conditions
          of Use at any time without notice. By continuing to use this site, you
          agree to be bound by the most current version of these Terms and
          Conditions.
        </p>
      </div>
    </div>
  );
};

export default TermsConditions;
