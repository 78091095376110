import React from "react";
import ArtificialImg from "../../../Assets/ArtificialAndAuto.jpg";
import "./ArtIntA.css";
const ArtIntA = () => {
  return (
    <div className="artificial-main-container">
      <div className="">
        <h1 className="artificial-heading">
          Artificial Intelligence & Automation:
        </h1>
        <div className="artificial-container">
          <p>
            At Agile TechAi, we harness the transformative power of artificial
            intelligence and automation to revolutionize business processes and
            drive innovation at scale. Our deep expertise in AI allows us to
            craft solutions that not only streamline operations but also
            significantly enhance productivity and efficiency. By automating
            repetitive tasks and optimizing workflows, we enable businesses to
            focus on strategic growth and innovation. Our AI-driven systems are
            meticulously designed to minimize manual intervention, ensuring
            precision and consistency in every operation. Furthermore, we
            prioritize delivering unparalleled customer experiences by
            integrating intelligent automation that responds proactively to
            customer needs. With Agile techai, businesses are empowered to
            achieve exceptional operational agility and a competitive edge in
            the ever-evolving digital landscape.
          </p>
          <img
            src={ArtificialImg}
            className="artificial-img"
            alt="artificial"
          />
        </div>
      </div>
    </div>
  );
};

export default ArtIntA;
