import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import About from "./About/About";
import Home from "./Home/Home";
import Contact from "./Contact/Contact";
import Layout from "./Layout";
import Careers from "./Careers/Careers";
import DataAnalytics from "./Services/DA/DataAnalytics";
import Cloud from "./Services/Cloud/Cloud";
import ArtIntA from "./Services/ArtIntA/ArtIntA";
import Banking from "./Industry/Banking/Banking";
import TermsConditions from "./Privacy/TermsConditions";
import PrivacyPolicy from "./Privacy/PrivacyPolicy";
import AppointmentForm from "./AppointmentForm/AppointmentForm";
import Healthcare from "./Industry/Healthcare/Healthcare";
import Ecommerce from "./Industry/Ecommerce/Ecommerce";
import Hotel from "./Industry/Hotel/Hotel";
import Industry from "./Industry/Industry";
import Services from "./Services/Services";

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/industry",
        element: <Industry />,
      },
      {
        path: "/industry/bank-financial",
        element: <Banking />, 
      },
      {
        path:"/industry/healthcare",
        element: <Healthcare/>
      },
      {
        path:"/industry/e-commerce",
        element: <Ecommerce/>
      },
      {
        path:"/industry/hotel-hospitality-solutions",
        element: <Hotel/>
      },
      {
        path: "/services",
        element: <Services />,
      },
      {
        path: "/services/cloud",
        element: <Cloud />,
      },
      {
        path: "/services/Data-analytics",
        element: <DataAnalytics />,
      },
      {
        path: "/services/artificial-intelligence-automation",
        element: <ArtIntA />, 
      },
      {
        path: "/contact", 
        element: <Contact />,
      },
      {
        path: "/careers",
        element: <Careers />,
      },
      {
        path: "/terms-and-conditions",
        element: <TermsConditions />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path:"/book-appointment",
        element:<AppointmentForm/>,
      },
      
    ],
  },
]);

const Body = () => {
  return (
    // <Provider store={store}>
    <RouterProvider router={appRouter} />
    // </Provider>
  );
};

export default Body;
